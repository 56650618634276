<template>
  <b-card
    class="card-congratulation-medal"
  >
    <h3 class="mt-4">Bienvenido(a) {{ userName }}!</h3>
    <b-card-text class="font-small-3">
      Manténgase al día con la información de su empresa
    </b-card-text>
    <b-row>
      <div class="mb-1 mt-1 pt-50">
        <b-form-group  class="ml-1 text-center">
          <b-avatar
                  size="45"
                  :variant="`light-primary`"
          >
            <feather-icon
                    size="21"
                    icon="CalendarIcon"
            />
          </b-avatar>
          <h3 class="mt-1" >
            {{date(new Date()).format('DD/MM/YYYY')}}
          </h3>

        </b-form-group>
      </div>
    </b-row>
    <div class="text-right">
      <b-img
        :src="require('@/assets/images/pages/reset-password-v2.svg')"
        class="welcome-img"
        alt="Medal Pic"
      />
    </div>

  </b-card>
</template>

<script>
import {
  BCard, BCardText, BImg, BAvatar
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import moment from 'moment'

export default {
  name: 'Welcome',
  components: {
    BCard,
    BCardText,
    BImg,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // date: new Date().toJSON.split('T')[0],
      date: moment,
      userName: '',
    }
  },
  mounted() {
    this.getUserName()
  },
  methods: {
    kFormatter,
    getUserName() {
      if (localStorage.getItem('user')) {
        const user = JSON.parse(localStorage.getItem('user'))
        console.log('user', user)

        this.userName = user.nombre_usuario
      }
    },
  },
}
</script>

<style scoped>
.welcome-img{
    position: absolute;
    width: 250px;
    top: 35px;
    right: 20px;
}
.col-lg-6 .welcome-img {
  position: absolute;
  width: 160px;
  top: 110px;
}
</style>
